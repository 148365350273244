import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, currency, translator, setOneOrMany } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput, InputCurrency, RangeInput, StatusBadge } from "components";
import { statuses } from "consts";
import { useFetchData, useCacheState, useQueryParams, useStickyState, useNewTabState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog, BiListUl } from "react-icons/bi";
import { IoMdCheckboxOutline } from "react-icons/io";

let loadCustomersTimeout, loadUsersTimeout;

const ApprovalTag = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const [data, isLoadingData] = useFetchData(
    useMemo(
      () => ({
        path: `/private/approvals/${queryParams.filter_approval_id}`,
        options: { isEnabled: _.isString(queryParams.filter_approval_id) },
      }),
      [queryParams.filter_approval_id]
    )
  );

  const handleClose = useCallback(() => {
    const params = { ...queryParams };
    delete params.filter_approval_id;
    setQueryParams(params, { replace: true });
  }, [queryParams, setQueryParams]);

  return (
    queryParams.filter_approval_id && (
      <HStack p="10px" _light={{ bg: "white" }} _dark={{ bg: "blackAlpha.100" }} borderRadius="lg" borderWidth="1px">
        <Center w="30px" h="30px" bg="blue.100" borderRadius="full">
          {isLoadingData ? <Spinner size="xs" color="blue.500" /> : <Icon as={IoMdCheckboxOutline} color="blue.500" />}
        </Center>
        <Box flex="1" pr="20px">
          <Text fontSize="xx-small">Aprovação #{data?.nid}</Text>
          <Text fontSize="xs" fontWeight="semibold">
            {currency(data?.amount)}
          </Text>
        </Box>
        <StatusBadge schema="approvals" status={data?.status} fontSize="10px" />
        <IconButton
          size="xs"
          variant="outline"
          icon={<Icon as={BiListUl} />}
          onClick={() => setQueryParams((params) => ({ ...params, approval_id: queryParams.filter_approval_id }))}
        />
        <IconButton size="xs" variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
      </HStack>
    )
  );
};

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const { queryParams } = useQueryParams();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? {},
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          scheduledDateStart: data.scheduledDateStart && moment(data.scheduledDateStart).toDate(),
          scheduledDateEnd: data.scheduledDateEnd && moment(data.scheduledDateEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(useMemo(() => ({ key: location.pathname.concat("filters.isOpen"), defaultValue: false }), []));
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    setOneOrMany("payment.referenceNumber", query.referenceNumber, response);
    setOneOrMany("payment.ourNumber", query.ourNumber, response);
    setOneOrMany("payment.nid", query.nid, response, (v) => parseInt(v));
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    if (query.status?.length) _.set(response, "payment.status.$in", _.map(query.status, "value"));
    if (query.createdBy?.length) _.set(response, "payment.createdBy.$in", toObjectId(query.createdBy));
    if (query.amountStart) _.set(response, "payment.amount.$gte", query.amountStart);
    if (query.amountEnd) _.set(response, "payment.amount.$lte", query.amountEnd);
    if (query.scheduledDateStart) _.set(response, "payment.scheduledDate.$gte", ["@ISODate", query.scheduledDateStart]);
    if (query.scheduledDateEnd) _.set(response, "payment.scheduledDate.$lte", ["@ISODate", query.scheduledDateEnd]);
    if (query.createdAtStart) _.set(response, "payment.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "payment.createdAt.$lte", ["@ISODate", query.createdAtEnd]);

    if (query.customer?.length) _.set(response, "customer._id.$in", toObjectId(query.customer));
    if (query.approvalStatus?.length) _.set(response, "approval.status.$in", _.map(query.approvalStatus, "value"));

    setOneOrMany("consumerUnit.cemigInstallationNumber", query.installationNumber, response);

    setIsFiltering(Object.keys(response).length > 0);
    if (queryParams.filter_approval_id) _.set(response, "approval._id", ["@ObjectId", queryParams.filter_approval_id]);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, queryParams, query, location.state]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
            <ApprovalTag />
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor do agendamento
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.amountStart}
                  defaultEndValue={formData.amountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, amountStart: startValue, amountEnd: endValue }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.payments}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.createdBy ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers}
                  placeholder="Selecione"
                  onChange={(createdBy) => setFormData((state) => ({ ...state, createdBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de agendamento
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.scheduledDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.scheduledDateStart}
                    defaultEndDate={formData.scheduledDateEnd}
                    onChange={(scheduledDateStart, scheduledDateEnd) =>
                      setFormData((state) => ({ ...state, scheduledDateStart, scheduledDateEnd }))
                    }
                  />
                  {formData.scheduledDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          scheduledDateStart: null,
                          scheduledDateEnd: null,
                          scheduledDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <Divider my={4} />

          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da aprovação
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.approvalStatus ?? []}
                  placeholder="Selecione"
                  options={statuses.approvals}
                  onChange={(approvalStatus) => setFormData((state) => ({ ...state, approvalStatus }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>

          <Divider my={4} />

          <Grid templateColumns="repeat(12,1fr)" gap={4} mb={8}>
            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Núm. de instalação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.installationNumber ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
                />
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
