import React, { memo, useMemo } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Icon, Link, Divider } from "@chakra-ui/react";
import { Breadcrumb, PermissionedContainer } from "components";
import { useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import { MdRemove } from "react-icons/md";

const tabs = [
  { hash: "#records", title: "cadastros" },
  { hash: "#imports", title: "importações" },
];

const ListItem = memo(({ to, title }) => {
  return (
    <HStack p="10px" transition="200ms" fontSize="sm">
      <Icon as={MdRemove} />
      <Link as={RouterLink} to={to}>
        {title}
      </Link>
    </HStack>
  );
});

const Container = () => {
  useDocumentTitle("Configurações");
  const location = useLocation();
  const activeIndex = useMemo(() => {
    const index = tabs.findIndex((o) => o.hash === location.hash);
    if (index !== -1) return index;
    return 0;
  }, [location.hash]);

  return (
    <Content>
      <HStack justify="space-between">
        <HStack spacing="20px">
          <Breadcrumb items={[{ label: "configurações" }]} />
        </HStack>
      </HStack>

      <Heading size="md" my="15px">
        Configurações
      </Heading>

      <Tabs colorScheme="main" defaultIndex={activeIndex}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.hash} as={RouterLink} to={tab.hash}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="10px">
            <PermissionedContainer required="users.read">
              <ListItem to="/users" title="Usuários" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="powerPlants.read">
              <ListItem to="/power-plants" title="Usinas" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="exhibitions.read">
              <ListItem to="/exhibitions" title="Eventos" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="segments.read">
              <ListItem to="/segments" title="Segmentos" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="tariffGroups.read">
              <ListItem to="/tariff-groups" title="Grupos tarifários" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="promotions.read">
              <ListItem to="/promotions" title="Promoções" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="expirationGroups.read">
              <ListItem to="/expiration-groups" title="Grupos de vencimento" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="sendingGroups.read">
              <ListItem to="/sending-groups" title="Grupos de envio" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="statuses.read">
              <ListItem to="/statuses" title="Status" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="userGroups.read">
              <ListItem to="/user-groups" title="Grupos de usuário" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="faq.read">
              <ListItem to="/faq" title="Perguntas frequentes" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="tariffBases.read">
              <ListItem to="/tariff-bases" title="Bases tarifárias" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="invoiceTemplates.read">
              <ListItem to="/invoice-templates" title="Templates de faturas" />
              <Divider />
            </PermissionedContainer>
          </TabPanel>
          <TabPanel p="0" pt="10px">
            <PermissionedContainer required="imports.consumerUnitsStatuses">
              <ListItem to="/settings/imports/consumer-units-statuses" title="Status das UCs" />
              <Divider />
            </PermissionedContainer>
            <PermissionedContainer required="imports.consumerUnitsPowerPlants">
              <ListItem to="/settings/imports/consumer-units-power-plants" title="Usinas das UCs" />
              <Divider />
            </PermissionedContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Content>
  );
};

export default Container;
