import React, { useCallback } from "react";
import { Box, FormControl, FormHelperText, FormLabel, HStack, StackDivider, Switch, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useConfirmDialog } from "hooks";

const useSubstatusMessageConfirmDialog = () => {
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const handleConfirmAction = useCallback(
    async ({ prevSubstatus, nextSubstatus, statuses, data, params }) => {
      if (params) return await confirmAction(params);
      if (data._id && prevSubstatus !== nextSubstatus) {
        const getSubstatus = (_id) => {
          const flatten = _(statuses.data).flatMap((o) => o.children);
          return flatten.find((o) => o._id === _id);
        };
        const prev = getSubstatus(prevSubstatus);
        const next = getSubstatus(nextSubstatus);
        const params = {
          isVisibleOutgoingMessage: _.isObject(prev?.outgoingEmail || prev?.outgoingWhatsapp),
          isVisibleIncomingMessage: _.isObject(next?.incomingEmail || next?.incomingWhatsapp),
        };
        if (Object.values(params).includes(true)) return await confirmAction(params);
      }
    },
    [confirmAction]
  );

  const StatusMessageConfirmDialog = useCallback(
    () => (
      <ConfirmDialog
        title="Atenção"
        description="Você está realizando uma alteração no status/substatus. Deseja notificar o cliente sobre esta alteração?"
        buttons={[
          { value: "cancel", text: "cancelar", variant: "outline" },
          { type: "submit", text: "continuar", colorScheme: "blue" },
        ]}
      >
        {({ formData, setFormData, params }) => (
          <VStack alignItems="stretch" divider={<StackDivider />} mt={4} spacing={4}>
            {params.isVisibleOutgoingMessage && (
              <FormControl as={HStack}>
                <Box flex="1">
                  <FormLabel fontSize="sm" m={0}>
                    Notificação de saída do status atual
                  </FormLabel>
                  <FormHelperText fontSize="xs" m={0}>
                    Enviar notificação de saída do status atual para o cliente.
                  </FormHelperText>
                </Box>
                <Switch
                  isChecked={formData._outgoingMessage}
                  onChange={() => setFormData((state) => ({ ...state, _outgoingMessage: !state._outgoingMessage }))}
                />
              </FormControl>
            )}
            {params.isVisibleIncomingMessage && (
              <FormControl as={HStack}>
                <Box flex="1">
                  <FormLabel fontSize="sm" m={0}>
                    Notificação de entrada do novo status
                  </FormLabel>
                  <FormHelperText fontSize="xs" m={0}>
                    Enviar notificação de entrada do novo status para o cliente.
                  </FormHelperText>
                </Box>
                <Switch
                  isChecked={formData._incomingMessage}
                  onChange={() => setFormData((state) => ({ ...state, _incomingMessage: !state._incomingMessage }))}
                />
              </FormControl>
            )}
          </VStack>
        )}
      </ConfirmDialog>
    ),
    [ConfirmDialog]
  );

  return [handleConfirmAction, StatusMessageConfirmDialog];
};

export default useSubstatusMessageConfirmDialog;
