import React, { useState, useMemo, useCallback, useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { Address, InputCurrency, BoxData, ElectricityAverageCalculator, SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { api, yup } from "lib";
import { useFetchData, useCustomToast, usePermissioned, useConfirmDialog } from "hooks";
import { messages, installationTypes, loyaltyTimesInMonths } from "consts";
import { MdHistory, MdRefresh } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import ConsumerUnitsContext from "./context";
import CustomersDetailsContext from "../context";
import { PrivateContext } from "pages/Private";
import AddSegment from "./addSegment";
import StatusHistory from "./statusHistory";
import SentToCollectionAgency from "./sentToCollectionAgency";
import useSubstatusMessageConfirmDialog from "./useSubstatusMessageConfirmDialog";

const defaultFormData = {
  isChangeOwnershipNecessary: false,
  voltage: "low",
  cemigPaymentMethod: "cash",
  isUnifiedInvoice: false,
  isPortability: false,
  isSameAddress: false,
  isBlockedCreateCharge: false,
  isLowIncome: false,
};

const Details = () => {
  const { _id } = useParams();
  const { currentUser } = useContext(PrivateContext);
  const { editSelected, setEditSelected } = useContext(ConsumerUnitsContext);
  const { formData: customerFormData, refreshConsumerUnits } = useContext(CustomersDetailsContext);
  const [formData, _setFormData] = useState({ ...defaultFormData, agreedDiscount: currentUser.userGroup.fixedAgreedDiscount });
  const [formErrors, setFormErrors] = useState({});
  const [powerPlants, isLoadingPowerPlants, refreshPowerPlants] = useFetchData(
    useMemo(
      () => ({
        path: "/private/power-plants",
        params: { query: { isActive: true }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const [segments, isLoadingSegments, refreshSegments] = useFetchData(
    useMemo(
      () => ({
        path: "/private/segments",
        params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const [_tariffGroups, isLoadingTariffGroups, refreshTariffGroups] = useFetchData(
    useMemo(
      () => ({
        path: "/private/tariff-groups",
        params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const [expirationGroups, isLoadingExpirationGroups, refreshExpirationGroups] = useFetchData(
    useMemo(
      () => ({
        path: "/private/expiration-groups",
        params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const [sendingGroups, isLoadingSendingGroups, refreshSendingGroups] = useFetchData(
    useMemo(
      () => ({
        path: "/private/sending-groups",
        params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );
  const tariffGroups = useMemo(() => {
    if (formData.isUnifiedInvoice) return _tariffGroups?.data;
    return _.filter(_tariffGroups?.data, (o) => o.modality !== "full_tariff");
  }, [_tariffGroups?.data, formData.isUnifiedInvoice]);
  const compensableTariffGroup = useMemo(
    () => _.find(_tariffGroups?.data, (o) => o.modality === "compensable_tariff"),
    [_tariffGroups?.data]
  );
  const defaultDynamicExpirationGroup = useMemo(
    () => _.find(expirationGroups?.data, (o) => o.type === "dynamic" && o.isLocked),
    [expirationGroups]
  );
  const [promotions, isLoadingPromotions, refreshPromotions] = useFetchData(
    useMemo(
      () => ({
        path: "/private/promotions",
        params: {
          query: { $or: [{ _id: editSelected?.promotion }, { isActive: true }] },
          sort: { title: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
        options: { isEnabled: _.isObject(editSelected) },
      }),
      [editSelected]
    )
  );
  const [statuses, isLoadingStatuses, refreshStatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", parent: { $exists: false }, isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
          withChildren: true,
        },
      }),
      []
    )
  );
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isAllowedCustomersRead = usePermissioned("customers.read");
  const isAllowedConsumerUnitsUpdate = usePermissioned("customers.consumerUnits.update.*");
  const isAllowedConsumerUnitsUpdateAfterFinished = usePermissioned("customers.consumerUnits.updateAfterContractAdded");
  const isSaveDisabled = useMemo(() => {
    if (formData.contract && !isAllowedConsumerUnitsUpdateAfterFinished) return true;
    return Object.keys(isAllowedConsumerUnitsUpdate).length === 0;
  }, [formData.contract, isAllowedConsumerUnitsUpdate, isAllowedConsumerUnitsUpdateAfterFinished]);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const [substatusMessageConfirmAction, SubstatusMessageConfirmDialog] = useSubstatusMessageConfirmDialog();

  const setFormData = useCallback((params, isPristine) => {
    if (params instanceof Function) _setFormData((state) => ({ ...params(state), isPristine }));
    else _setFormData({ ...params, isPristine });
  }, []);

  useEffect(() => {
    const formData = editSelected?._id
      ? { ...editSelected }
      : { ...defaultFormData, agreedDiscount: currentUser.userGroup.fixedAgreedDiscount };

    if (!formData.status) formData.status = _.find(statuses?.data, (o) => o.title.match(/Em adesão/i));
    if (!formData.substatus) formData.substatus = _.find(formData.status?.children, (o) => o.title.match(/Análise pendente/i));

    if (!formData.powerPlant) formData.powerPlant = _.find(powerPlants?.data, (o) => o.isPrimary);
    if (!formData.tariffGroup) formData.tariffGroup = compensableTariffGroup;
    if (!formData.expirationGroup) formData.expirationGroup = defaultDynamicExpirationGroup;
    if (formData.createChargeBlockedUntilDate)
      formData.createChargeBlockedUntilDate = moment(formData.createChargeBlockedUntilDate).format("DD/MM/YYYY");
    setFormData(formData, true);
    setFormErrors({});
  }, [
    editSelected,
    powerPlants?.data,
    statuses?.data,
    compensableTariffGroup,
    defaultDynamicExpirationGroup,
    currentUser.userGroup.fixedAgreedDiscount,
  ]);

  const isEditDisabled = useCallback(
    (key) => {
      if (_.isString(editSelected?._id)) return !isAllowedConsumerUnitsUpdate[key];
      return false;
    },
    [editSelected?._id, isAllowedConsumerUnitsUpdate]
  );

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id
          ? await api.patch(`/private/customers/${_id}/consumer-units/${data._id}`, data)
          : await api.put(`/private/customers/${_id}/consumer-units`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setEditSelected();
        refreshConsumerUnits();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshConsumerUnits, toast, setEditSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        substatus: yup.string().required(messages.error.required),
        cemigInstallationNumber: yup.string().isValidCemigNumber(3).required(messages.error.required),
        segment: yup.string().nullable(false).typeError(messages.error.required).required(messages.error.required),
        installationType: yup.string().required(messages.error.required),
        customerClass: yup.string().required(messages.error.required),
        electricityBillAmount: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
        electricityAverageSpentInKwh: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
        agreedDiscount: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          number: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required).max(20, messages.error.maxLength(20)),
          city: yup.string().required(messages.error.required),
          state: yup.string().required(messages.error.required),
        }),
        voltage: yup.string().required(messages.error.required),
        cemigPaymentMethod: yup.string().required(messages.error.required),
        distributorInvoiceReceiptMethod: yup.string().required(messages.error.required),
        loyaltyTimeInMonths: yup.string().required(messages.error.required),
        invoiceReceiptMethod: yup.string().required(messages.error.required),
        powerPlant: yup.string().nullable(false).typeError(messages.error.required).required(messages.error.required),
        invoiceReceiptMethodJustify: yup.string().when("invoiceReceiptMethod", {
          is: "others",
          then: yup.string().required(messages.error.required),
        }),
        isPortability: yup.boolean(),
        portabilityCompanyName: yup.string().when("isPortability", {
          is: true,
          then: yup.string().required(messages.error.required),
        }),
        invoiceDueDay: yup
          .number()
          .min(1, `${messages.error.greaterOrEqual} 1.`)
          .max(31, `${messages.error.lessOrEqual} 31.`)
          .required(messages.error.required),
        approximateReadingDay: yup
          .number()
          .min(1, `${messages.error.greaterOrEqual} 1.`)
          .max(31, `${messages.error.lessOrEqual} 31.`)
          .required(messages.error.required),
        tariffGroup: yup.string().nullable(false).typeError(messages.error.required).required(messages.error.required),
        createChargeBlockedUntilDate: yup.date().nullable(true).typeError(messages.error.invalidDate),
      });
      const data = {
        ...formData,
        status: formData.status?._id || null,
        substatus: formData.substatus?._id || null,
        segment: formData.segment?._id || null,
        powerPlant: formData.powerPlant?._id || null,
        tariffGroup: formData.tariffGroup?._id || null,
        promotion: formData.promotion?._id || null,
        expirationGroup: formData.expirationGroup?._id || null,
        sendingGroup: formData.sendingGroup?._id || null,
        createChargeBlockedUntilDate:
          formData.isBlockedCreateCharge && formData.createChargeBlockedUntilDate
            ? moment(formData.createChargeBlockedUntilDate, "DD/MM/YYYY").toDate()
            : null,
      };
      await schema.validate(data, { abortEarly: false });

      const result = await substatusMessageConfirmAction({
        prevSubstatus: editSelected.substatus?._id,
        nextSubstatus: data.substatus,
        statuses,
        data,
      });
      if (result === "cancel") return;
      data._outgoingMessage = result?._outgoingMessage;
      data._incomingMessage = result?._incomingMessage;
      handleSaveData(data);

      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [statuses, editSelected, formData, handleSaveData]);

  const handleSameAddress = useCallback(() => {
    setFormData((state) => {
      const tmp = { ...state };
      tmp.isSameAddress = !state.isSameAddress;
      if (tmp.isSameAddress && customerFormData.address) {
        const { _id, createdAt, updatedAt, ...address } = customerFormData.address;
        tmp.address = address;
      } else tmp.address = {};
      return tmp;
    });
  }, [customerFormData.address]);

  const handleAddSegment = useCallback(
    (segment) => {
      setFormData((state) => ({ ...state, segment }));
      refreshSegments();
    },
    [refreshSegments]
  );

  const handleClose = useCallback(() => {
    if (!formData.isPristine) {
      const confirm = window.confirm("Deseja realmente sair sem salvar?");
      if (!confirm) return;
    }
    setEditSelected();
  }, [formData.isPristine]);

  return (
    <>
      <Modal
        size="full"
        isOpen={editSelected}
        onClose={handleClose}
        scrollBehavior="inside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Box flex="1">
              <Text lineHeight="xs">Unidade consumidora</Text>
              <Text fontSize="xs" fontWeight="normal">
                {editSelected?._id}
              </Text>
            </Box>
            {editSelected?._id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
            <HStack>
              <Text fontSize="xs">Ativo?</Text>
              <Switch
                size="sm"
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
                isDisabled={isEditDisabled("isActive")}
              />
            </HStack>
          </ModalHeader>
          <Divider />
          <ModalBody py={4}>
            {formData.sentToCollectionAgency && (
              <HStack bg="purple.100" p="15px" borderRadius="lg" mb={4}>
                <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
                  <Icon as={BiSupport} boxSize="25px" />
                </Center>
                <Box flex="1">
                  <Heading size="sm">UC em cobrança terceirizada</Heading>
                  <Text fontSize="xs">
                    Esta UC foi enviada para cobrança terceirizada em{" "}
                    {moment(formData.sentToCollectionAgencyAt).format("DD/MM/YYYY [às] HH:mm:ss")} por{" "}
                    {formData.sentToCollectionAgencyBy?.name || "-"}
                  </Text>
                </Box>
              </HStack>
            )}

            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.invoiceDueDay}>
                  <FormLabel fontSize="sm">Dia de vencimento da fatura</FormLabel>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.invoiceDueDay ?? ""}
                    onChange={(invoiceDueDay) => setFormData((state) => ({ ...state, invoiceDueDay }))}
                    isDisabled={isEditDisabled("invoiceDueDay")}
                  />
                  <FormErrorMessage>{formErrors.invoiceDueDay}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isInvalid={formErrors.electricityBillAmount}>
                  <FormLabel fontSize="sm">Valor da conta</FormLabel>
                  <InputGroup>
                    <InputLeftElement fontSize="xs">R$</InputLeftElement>
                    <Input
                      as={InputCurrency}
                      value={formData.electricityBillAmount ?? ""}
                      onChange={(electricityBillAmount) => setFormData((state) => ({ ...state, electricityBillAmount }))}
                      isDisabled={isEditDisabled("electricityBillAmount")}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formErrors.electricityBillAmount}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <HStack>
                  {/* {formData.contract ? (
                    <BoxData
                      flex="1"
                      label="Média do contrato"
                      value={`${formData.electricityAverageSpentInKwh?.toLocaleString() || 0}kWh`}
                      RightComponent={<ElectricityAverageCalculator data={formData.electricityAverageSpentInKwhMonths} isDisabled={true} />}
                    />
                  ) : ( */}
                  <FormControl flex="1" isRequired={true} isInvalid={formErrors.electricityAverageSpentInKwh}>
                    <FormLabel fontSize="sm">Média do contrato</FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <ElectricityAverageCalculator
                          data={formData.electricityAverageSpentInKwhMonths}
                          onChange={(electricityAverageSpentInKwhMonths, electricityAverageSpentInKwh) =>
                            setFormData((state) => ({
                              ...state,
                              electricityAverageSpentInKwhMonths,
                              electricityAverageSpentInKwh,
                            }))
                          }
                          isDisabled={isEditDisabled("electricityAverageSpentInKwh")}
                        />
                      </InputLeftElement>
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={formData.electricityAverageSpentInKwh ?? ""}
                        onChange={(electricityAverageSpentInKwh) => setFormData((state) => ({ ...state, electricityAverageSpentInKwh }))}
                        isDisabled={isEditDisabled("electricityAverageSpentInKwh")}
                      />
                      <InputRightElement fontSize="xs">kWh</InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.electricityAverageSpentInKwh}</FormErrorMessage>
                  </FormControl>
                  {/* )} */}
                  {formData.contract && (
                    <FormControl flex="1" isRequired={true} isInvalid={formErrors.checkedElectricityAverageSpentInKwh}>
                      <FormLabel fontSize="sm">Média corrigida</FormLabel>
                      <InputGroup>
                        <InputLeftElement>
                          <ElectricityAverageCalculator
                            data={formData.checkedElectricityAverageSpentInKwhMonths}
                            onChange={(checkedElectricityAverageSpentInKwhMonths, checkedElectricityAverageSpentInKwh) =>
                              setFormData((state) => ({
                                ...state,
                                checkedElectricityAverageSpentInKwhMonths,
                                checkedElectricityAverageSpentInKwh,
                              }))
                            }
                            isDisabled={isEditDisabled("checkedElectricityAverageSpentInKwh")}
                          />
                        </InputLeftElement>
                        <Input
                          as={InputCurrency}
                          precision="0"
                          value={formData.checkedElectricityAverageSpentInKwh ?? ""}
                          onChange={(checkedElectricityAverageSpentInKwh) =>
                            setFormData((state) => ({ ...state, checkedElectricityAverageSpentInKwh }))
                          }
                          isDisabled={isEditDisabled("checkedElectricityAverageSpentInKwh")}
                        />
                        <InputRightElement fontSize="xs">kWh</InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{formErrors.checkedElectricityAverageSpentInKwh}</FormErrorMessage>
                    </FormControl>
                  )}
                </HStack>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <HStack>
                  <FormControl isInvalid={formErrors.currentGenerationBalanceInKwh}>
                    <FormLabel fontSize="sm">Saldo atual de geração</FormLabel>
                    <InputGroup>
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={formData.currentGenerationBalanceInKwh ?? ""}
                        onChange={(currentGenerationBalanceInKwh) => setFormData((state) => ({ ...state, currentGenerationBalanceInKwh }))}
                        isDisabled={isEditDisabled("currentGenerationBalanceInKwh")}
                      />
                      <InputRightElement fontSize="xs">kWh</InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.currentGenerationBalanceInKwh}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.ownGenerationInKwh}>
                    <FormLabel fontSize="sm">Geração própria</FormLabel>
                    <InputGroup>
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={formData.ownGenerationInKwh ?? ""}
                        onChange={(ownGenerationInKwh) => setFormData((state) => ({ ...state, ownGenerationInKwh }))}
                        isDisabled={isEditDisabled("ownGenerationInKwh")}
                      />
                      <InputRightElement fontSize="xs">kWh</InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.ownGenerationInKwh}</FormErrorMessage>
                  </FormControl>
                </HStack>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.segment}>
                  <FormLabel fontSize="sm">Segmento</FormLabel>
                  <HStack>
                    <Select
                      value={formData.segment?._id ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, segment: { _id: target.value } }))}
                      isDisabled={isEditDisabled("segment")}
                    >
                      <option value="">--Selecione</option>
                      {_.map(segments?.data, (o) => (
                        <option key={o._id} value={o._id}>
                          {o.title}
                        </option>
                      ))}
                    </Select>
                    <AddSegment isLoading={isLoadingSegments} onChange={handleAddSegment} isDisabled={isEditDisabled("segment")} />
                  </HStack>
                  <FormErrorMessage>{formErrors.segment}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors?.installationType}>
                  <FormLabel fontSize="sm">Tipo de instalação</FormLabel>
                  <Select
                    value={formData?.installationType ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, installationType: target.value }))}
                    isDisabled={isEditDisabled("installationType")}
                  >
                    <option value="">--Selecione</option>
                    {installationTypes.map((item) => (
                      <option key={item} value={item}>
                        {item.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formErrors?.installationType}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors?.customerClass}>
                  <FormLabel fontSize="sm">Classe do cliente</FormLabel>
                  <Select
                    value={formData?.customerClass ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, customerClass: target.value }))}
                    isDisabled={isEditDisabled("customerClass")}
                  >
                    <option value="">--Selecione</option>
                    {["comercial", "residencial", "rural", "industrial"].map((item) => (
                      <option key={item} value={item}>
                        {item.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formErrors?.customerClass}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.approximateReadingDay}>
                  <FormLabel fontSize="sm">Dia aprox. da leitura</FormLabel>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.approximateReadingDay ?? ""}
                    onChange={(approximateReadingDay) => setFormData((state) => ({ ...state, approximateReadingDay }))}
                    isDisabled={isEditDisabled("approximateReadingDay")}
                  />
                  <FormErrorMessage>{formErrors.approximateReadingDay}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.agreedDiscount}>
                  <FormLabel fontSize="sm">Desconto acordado</FormLabel>
                  <InputGroup>
                    <Input
                      as={InputCurrency}
                      precision="0"
                      value={formData.agreedDiscount ?? ""}
                      onChange={(agreedDiscount) => setFormData((state) => ({ ...state, agreedDiscount }))}
                      isPercentage={true}
                      isDisabled={isEditDisabled("agreedDiscount")}
                    />
                    <InputRightElement fontSize="xs">%</InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{formErrors.agreedDiscount}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isChangeOwnershipNecessary}>
                  <FormLabel fontSize="sm">Necessária troca de titularidade?</FormLabel>
                  <Select
                    value={formData.isChangeOwnershipNecessary ? "yes" : "no"}
                    onChange={({ target }) => setFormData((state) => ({ ...state, isChangeOwnershipNecessary: target.value === "yes" }))}
                    isDisabled={isEditDisabled("isChangeOwnershipNecessary")}
                  >
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isChangeOwnershipNecessary}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors?.cemigInstallationNumber}>
                  <FormLabel fontSize="sm">Número de instalação na CEMIG</FormLabel>
                  <Input
                    value={formData?.cemigInstallationNumber ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, cemigInstallationNumber: target.value }))}
                    isDisabled={isEditDisabled("cemigInstallationNumber")}
                  />
                  <FormErrorMessage>{formErrors?.cemigInstallationNumber}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.powerPlant}>
                  {isAllowedCustomersRead ? (
                    <>
                      <FormLabel fontSize="sm">Usina</FormLabel>
                      <HStack>
                        <Select
                          value={formData.powerPlant?._id ?? ""}
                          onChange={({ target }) => setFormData((state) => ({ ...state, powerPlant: { _id: target.value } }))}
                          isDisabled={isEditDisabled("powerPlant")}
                        >
                          <option value="yes">--Selecione</option>
                          {_.map(powerPlants?.data, (o) => (
                            <option key={o._id} value={o._id}>
                              {o.name}
                            </option>
                          ))}
                        </Select>
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingPowerPlants}
                          onClick={refreshPowerPlants}
                        />
                      </HStack>
                    </>
                  ) : (
                    <BoxData label="Usina" value={formData.powerPlant?.name ?? "-"} />
                  )}
                  <FormErrorMessage>{formErrors.powerPlant}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.voltage}>
                  <FormLabel fontSize="sm">Tensão da unidade</FormLabel>
                  <Select
                    value={formData.voltage ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, voltage: target.value }))}
                    isDisabled={isEditDisabled("voltage")}
                  >
                    <option value="">--Selecione</option>
                    <option value="high">Alta tensão</option>
                    <option value="low">Baixa tensão</option>
                  </Select>
                  <FormErrorMessage>{formErrors.voltage}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.loyaltyTimeInMonths}>
                  <FormLabel fontSize="sm">Tempo de fidelidade</FormLabel>
                  <Select
                    value={formData.loyaltyTimeInMonths ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, loyaltyTimeInMonths: target.value }))}
                    isDisabled={isEditDisabled("loyaltyTimeInMonths")}
                  >
                    <option value="">--Selecione</option>
                    {loyaltyTimesInMonths.map((item) => (
                      <option key={item} value={item}>
                        {item} meses
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formErrors.loyaltyTimeInMonths}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.cemigPaymentMethod}>
                  <FormLabel fontSize="sm">Forma de pagamento atual na CEMIG</FormLabel>
                  <Select
                    value={formData.cemigPaymentMethod ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, cemigPaymentMethod: target.value }))}
                    isDisabled={isEditDisabled("cemigPaymentMethod")}
                  >
                    <option value="">--Selecione</option>
                    <option value="cash">Dinheiro</option>
                    {!formData.isUnifiedInvoice && (
                      <Fragment>
                        <option value="direct_debit">Débito automático</option>
                        <option value="pix">Pix</option>
                      </Fragment>
                    )}
                  </Select>
                  <FormErrorMessage>{formErrors.cemigPaymentMethod}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: formData.invoiceReceiptMethod === "others" ? 3 : 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.invoiceReceiptMethod}>
                  <FormLabel fontSize="sm">Como deseja receber a fatura?</FormLabel>
                  <Select
                    value={formData.invoiceReceiptMethod ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, invoiceReceiptMethod: target.value }))}
                    isDisabled={isEditDisabled("invoiceReceiptMethod")}
                  >
                    <option value="">--Selecione</option>
                    <option value="email">E-mail</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="others">Outros</option>
                  </Select>
                  <FormErrorMessage>{formErrors.invoiceReceiptMethod}</FormErrorMessage>
                </FormControl>
              </GridItem>
              {formData.invoiceReceiptMethod === "others" && (
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <FormControl isRequired={true} isInvalid={formErrors.invoiceReceiptMethodJustify}>
                    <FormLabel fontSize="sm">Explique como deseja receber a fatura</FormLabel>
                    <Input
                      value={formData.invoiceReceiptMethodJustify ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, invoiceReceiptMethodJustify: target.value }))}
                      isDisabled={isEditDisabled("invoiceReceiptMethodJustify")}
                    />
                    <FormErrorMessage>{formErrors.invoiceReceiptMethodJustify}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isUnifiedInvoice}>
                  <FormLabel fontSize="sm">Modalidade de fatura</FormLabel>
                  <Select
                    value={formData.isUnifiedInvoice ? "yes" : "no"}
                    onChange={({ target }) =>
                      setFormData((state) => {
                        const tmp = { ...state, isUnifiedInvoice: target.value === "yes", tariffGroup: compensableTariffGroup };
                        if (tmp.isUnifiedInvoice) {
                          tmp.cemigPaymentMethod = "cash";
                          tmp.expirationGroup = _.find(
                            expirationGroups?.data,
                            (o) => o.type === "dynamic" && o.daysBeforeDueDate !== 0 && o.isLocked === true
                          );
                        } else
                          tmp.expirationGroup = _.find(
                            expirationGroups?.data,
                            (o) => o.type === "dynamic" && o.daysBeforeDueDate === 0 && o.isLocked == true
                          );
                        return tmp;
                      })
                    }
                    isDisabled={isEditDisabled("isUnifiedInvoice")}
                  >
                    <option value="yes">Fatura unificada</option>
                    <option value="no">Fatura separada</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isUnifiedInvoice}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.distributorInvoiceReceiptMethod}>
                  <FormLabel fontSize="sm">Recebimento fatura CEMIG</FormLabel>
                  <Select
                    value={formData.distributorInvoiceReceiptMethod ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, distributorInvoiceReceiptMethod: target.value }))}
                    isDisabled={isEditDisabled("distributorInvoiceReceiptMethod")}
                  >
                    <option value="">--Selecione</option>
                    <option value="standard">Padrão CEMIG</option>
                    <option value="changed">Alterado Click</option>
                  </Select>
                  <FormErrorMessage>{formErrors.distributorInvoiceReceiptMethod}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: formData.isPortability ? 3 : 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isPortability}>
                  <FormLabel fontSize="sm">Portabilidade?</FormLabel>
                  <Select
                    value={formData.isPortability ? "yes" : "no"}
                    onChange={({ target }) => setFormData((state) => ({ ...state, isPortability: target.value === "yes" }))}
                    isDisabled={isEditDisabled("isPortability")}
                  >
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isPortability}</FormErrorMessage>
                </FormControl>
              </GridItem>

              {formData.isPortability && (
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <FormControl isRequired={true} isInvalid={formErrors.portabilityCompanyName}>
                    <FormLabel fontSize="sm">Empresa de origem</FormLabel>
                    <Input
                      value={formData.portabilityCompanyName ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, portabilityCompanyName: target.value }))}
                      isDisabled={isEditDisabled("portabilityCompanyName")}
                    />
                    <FormErrorMessage>{formErrors.portabilityCompanyName}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.tariffGroup}>
                  {isAllowedCustomersRead ? (
                    <>
                      <FormLabel fontSize="sm">Grupo tarifário</FormLabel>
                      <HStack>
                        <SyncSelect
                          placeholder="Selecione"
                          options={tariffGroups}
                          value={formData.tariffGroup || {}}
                          onChange={(tariffGroup) => setFormData((state) => ({ ...state, tariffGroup }))}
                          getOptionValue={({ _id }) => _id}
                          formatOptionLabel={({ title }) => title}
                          isDisabled={isEditDisabled("tariffGroup")}
                        />
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingTariffGroups}
                          onClick={refreshTariffGroups}
                        />
                      </HStack>
                    </>
                  ) : (
                    <BoxData label="Grupo tarifário" value={formData.tariffGroup?.title ?? "-"} />
                  )}
                  <FormErrorMessage>{formErrors.tariffGroup}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.expirationGroup}>
                  {isAllowedCustomersRead ? (
                    <Fragment>
                      <FormLabel fontSize="sm">Grupo de vencimento</FormLabel>
                      <HStack>
                        <SyncSelect
                          placeholder="Selecione"
                          options={expirationGroups?.data || []}
                          value={formData.expirationGroup || {}}
                          onChange={(expirationGroup) => setFormData((state) => ({ ...state, expirationGroup }))}
                          getOptionValue={({ _id }) => _id}
                          formatOptionLabel={({ title }) => title}
                          isDisabled={isEditDisabled("expirationGroup")}
                        />
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingExpirationGroups}
                          onClick={refreshExpirationGroups}
                        />
                      </HStack>
                    </Fragment>
                  ) : (
                    <BoxData label="Grupo de vencimento" value={formData.expirationGroup?.title ?? "-"} />
                  )}
                  <FormErrorMessage>{formErrors.expirationGroup}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.sendingGroup}>
                  {isAllowedCustomersRead ? (
                    <Fragment>
                      <FormLabel fontSize="sm">Grupo de envio</FormLabel>
                      <HStack>
                        <SyncSelect
                          placeholder="Selecione"
                          options={sendingGroups?.data || []}
                          value={formData.sendingGroup ?? {}}
                          onChange={(sendingGroup) => setFormData((state) => ({ ...state, sendingGroup }))}
                          getOptionValue={({ _id }) => _id}
                          formatOptionLabel={({ title }) => title}
                          isDisabled={isEditDisabled("sendingGroup")}
                        />
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingSendingGroups}
                          onClick={refreshSendingGroups}
                        />
                      </HStack>
                    </Fragment>
                  ) : (
                    <BoxData label="Grupo de envio" value={formData.sendingGroup?.title ?? "-"} />
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.promotion}>
                  <FormLabel fontSize="sm">Promoção</FormLabel>
                  <HStack>
                    <SyncSelect
                      placeholder="Selecione"
                      options={promotions?.data || []}
                      value={formData.promotion || {}}
                      onChange={(promotion) => setFormData((state) => ({ ...state, promotion }))}
                      getOptionValue={({ _id }) => _id}
                      formatOptionLabel={({ title }) => title}
                      isDisabled={isEditDisabled("promotion")}
                    />
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingPromotions}
                      onClick={refreshPromotions}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.promotion}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 5 }}>
                <FormControl isRequired={true} isInvalid={formErrors.status}>
                  {isAllowedCustomersRead ? (
                    <>
                      <FormLabel fontSize="sm">Status</FormLabel>
                      <HStack>
                        <SyncSelect
                          placeholder="Selecione"
                          options={statuses?.data || []}
                          value={formData.status || {}}
                          onChange={(status) => setFormData((state) => ({ ...state, status, substatus: null }))}
                          getOptionValue={({ _id }) => _id}
                          formatOptionLabel={({ colorScheme, title, ordination }) => (
                            <HStack>
                              <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                              <Text>
                                {ordination} - {title}
                              </Text>
                            </HStack>
                          )}
                          isDisabled={isEditDisabled("status")}
                        />
                        <IconButton
                          variant="outline"
                          icon={<Icon as={MdRefresh} />}
                          isLoading={isLoadingStatuses}
                          onClick={refreshStatuses}
                        />
                      </HStack>
                    </>
                  ) : (
                    <BoxData label="Status" value={formData.status?.title ?? "-"} />
                  )}
                  <FormErrorMessage>{formErrors.status}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 5 }}>
                <FormControl isRequired={true} isInvalid={formErrors.substatus}>
                  {isAllowedCustomersRead ? (
                    <>
                      <FormLabel fontSize="sm">Substatus</FormLabel>
                      <SyncSelect
                        placeholder="Selecione"
                        options={formData.status?.children}
                        value={formData.substatus || {}}
                        onChange={(substatus) => setFormData((state) => ({ ...state, substatus }))}
                        getOptionValue={({ _id }) => _id}
                        formatOptionLabel={({ colorScheme, title, ordination }) => (
                          <HStack>
                            <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                            <Text>
                              {ordination} - {title}
                            </Text>
                          </HStack>
                        )}
                        isDisabled={isEditDisabled("substatus")}
                      />
                    </>
                  ) : (
                    <BoxData label="Substatus" value={formData.substatus?.title ?? "-"} />
                  )}
                  <FormErrorMessage>{formErrors.substatus}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 2 }}>
                <FormControl>
                  <FormLabel fontSize="sm">&nbsp;</FormLabel>
                  <StatusHistory />
                </FormControl>
              </GridItem>

              {isAllowedCustomersRead && (
                <Fragment>
                  <GridItem colSpan={{ base: 12, lg: 3 }}>
                    <FormControl isRequired={true} isInvalid={formErrors.isBlockedCreateCharge}>
                      <FormLabel fontSize="sm">Bloquear emissão de cobranças?</FormLabel>
                      <Select
                        value={formData.isBlockedCreateCharge ? "yes" : "no"}
                        onChange={({ target }) => setFormData((state) => ({ ...state, isBlockedCreateCharge: target.value === "yes" }))}
                        isDisabled={isEditDisabled("isBlockedCreateCharge")}
                      >
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Select>
                      <FormErrorMessage>{formErrors.isBlockedCreateCharge}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  {formData.isBlockedCreateCharge && (
                    <GridItem colSpan={3}>
                      <FormControl isInvalid={formErrors.createChargeBlockedUntilDate}>
                        <FormLabel fontSize="sm">Desbloqueio automático em</FormLabel>
                        <Input
                          as={InputMask}
                          mask="99/99/9999"
                          value={formData.createChargeBlockedUntilDate ?? ""}
                          onChange={({ target }) => setFormData((state) => ({ ...state, createChargeBlockedUntilDate: target.value }))}
                          isDisabled={isEditDisabled("createChargeBlockedUntilDate")}
                        />
                        <FormErrorMessage>{formErrors.createChargeBlockedUntilDate}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  )}
                </Fragment>
              )}

              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isLowIncome}>
                  <FormLabel fontSize="sm">Baixa renda?</FormLabel>
                  <Select
                    value={formData.isLowIncome ? "yes" : "no"}
                    onChange={({ target }) => setFormData((state) => ({ ...state, isLowIncome: target.value === "yes" }))}
                    isDisabled={isEditDisabled("isLowIncome")}
                  >
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isLowIncome}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={8} />

            <HStack mb={6}>
              <Heading size="sm" flex="1">
                Endereço
              </Heading>
              <Checkbox isChecked={formData.isSameAddress} onChange={handleSameAddress}>
                <Text fontSize="sm">Mesmo endereço do titular?</Text>
              </Checkbox>
            </HStack>
            <Address
              data={formData.address}
              errors={formErrors.address}
              isRequired={true}
              onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
              isDisabled={isEditDisabled("address")}
            />

            <Divider my={8} />

            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isInvalid={formErrors.comments}>
                  <FormLabel fontSize="sm">Observações</FormLabel>
                  <Textarea
                    value={formData.comments ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
                    isDisabled={isEditDisabled("comments")}
                  />
                  <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            {editSelected?._id && (
              <DocumentHistory
                path={`/private/customers/${_id}/consumer-units/${editSelected._id}/history`}
                isOpen={isOpenDocumentHistory}
                onClose={onCloseDocumentHistory}
              />
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <SentToCollectionAgency />
            <Box flex="1" />
            <Button size="sm" variant="outline" onClick={handleClose}>
              {isSaveDisabled ? "fechar" : "cancelar"}
            </Button>
            {!isSaveDisabled && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SubstatusMessageConfirmDialog />
    </>
  );
};

export default Details;
